import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Accordion.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/EventTeaser.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Gallery.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Testimonial.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/@mui/icons-material/Check.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/icons-material/esm/Email.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/icons-material/esm/Facebook.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/icons-material/esm/Instagram.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/@mui/icons-material/FormatQuote.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/bg/51-2X4A7666.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logo/SPD_Logo_Weiss.svg");
