"use client";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
} from "@material-tailwind/react";

interface ITestimonialProps {
  name: string;
  title: string;
  text: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  imageSrc?: string;
}
export default function Testimonial({
  name,
  title,
  text,
  size = "xl",
  imageSrc = "/images/gallery/frank-ortolf-002.jpg",
}: ITestimonialProps) {
  return (
    <Card
      color="transparent"
      shadow={false}
      className="w-full flex flex-col items-center"
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <CardHeader
        color="transparent"
        floated={false}
        shadow={false}
        className="mx-0 flex items-center gap-4 pt-0 pb-8"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <Avatar
          size={size}
          variant="circular"
          src={imageSrc}
          alt={name}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
        <div className="flex w-full flex-col gap-0.5">
          <div className="flex items-center justify-between">
            <Typography
              variant="h4"
              color="blue-gray"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {name}
            </Typography>
          </div>
          <Typography
            color="blue-gray"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {title}
          </Typography>
        </div>
      </CardHeader>
      <CardBody
        className="mb-6 p-0 w-4/5"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <Typography
          variant="lead"
          className="text-center"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          &bdquo;{text}&rdquo;
        </Typography>
      </CardBody>
    </Card>
  );
}
