"use client";
import { useState } from "react";
import PhotoAlbum, { LayoutType, Photo } from "react-photo-album";
import Lightbox, { SlideImage } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export interface IGallery {
  slides: SlideImage[];
  layout?: LayoutType;
}

export default function Gallery({ slides, layout = "rows" }: IGallery) {
  const [index, setIndex] = useState(-1);
  return (
    <>
      <PhotoAlbum
        layout={layout as LayoutType}
        photos={slides as Photo[]}
        targetRowHeight={200}
        onClick={({ index: current }) => setIndex(current)}
      />
      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
        animation={{ fade: 300 }}
      />
    </>
  );
}
