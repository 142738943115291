/* eslint-disable @next/next/no-async-client-component */
"use client";
import { useEffect, useState } from "react";
import cn from "classnames";

export interface IEventTeaser {
  className?: string;
}

type TEvenetData = {
  active?: boolean;
  date: string;
  title: string;
  description: string;
};

async function EventTeaser({ className }: IEventTeaser) {
  const classes = cn(className);
  const [data, setData] = useState([] as TEvenetData[]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    try {
      fetch("/api/dates")
        .then((res) => res.json())
        .catch(() => setLoading(false))
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (!data || !Array.isArray(data))
    return (
      <p className="text-gray-500 text-lg md:text-xl text-left">
        keine anstehenden Termine
      </p>
    );
  return (
    <div className={classes}>
      {Array.isArray(data) &&
        data?.map(
          (event: TEvenetData) =>
            event.active && (
              <div key={event.date} className="flex flex-col space-y-2 mb-2">
                <p className="font-bold text-lg md:text-xl text-left">
                  {event.date}, {event.title}
                </p>
                <p className="text-l md:text-xl">{event.description}</p>
              </div>
            ),
        )}
    </div>
  );
}

export default EventTeaser;
