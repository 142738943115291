"use client";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ open }: { open: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

interface IPageAccordionProps {
  header: React.ReactNode;
  body: React.ReactNode;
  open?: boolean;
  className?: string;
}
export default function PageAccordion({
  open = false,
  header,
  body,
  className,
}: IPageAccordionProps): JSX.Element {
  const [openState, setOpenState] = useState(open);
  useEffect(() => {
    setOpenState(open);
  }, [open]);
  const handleOnClick = () => {
    setOpenState(!openState);
  };
  return (
    <Accordion
      open={openState}
      icon={<Icon open={openState} />}
      placeholder={null}
      className={className}
      onPointerEnterCapture=""
      onPointerLeaveCapture=""
    >
      <AccordionHeader
        onClick={handleOnClick}
        placeholder=""
        onPointerEnterCapture=""
        onPointerLeaveCapture=""
      >
        {header}
      </AccordionHeader>
      <AccordionBody>{body}</AccordionBody>
    </Accordion>
  );
}
